@import '../../../../../shared/utils/variable.scss';

@mixin open-sans-font {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 14.4px;
	text-align: center;
	vertical-align: middle;
}

@mixin geomanist-font {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 16px;
	line-height: 21.677px;
	text-align: center;
	vertical-align: middle;
}

.links {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 390px;
	align-items: center;
	padding: 0;

	.container-img {
		background-color: #ffffff;
		height: 81px;
		width: 100%;
		padding: 12px 0px;
		display: flex;
		align-items: center;
		justify-content: center;

		.dine-rewards-logo {
			width: 75px;
			@media (max-width: $TABLET_BREAKPOINT) {
				width: 70px;
			}
		}

		.outback-logo {
			width: 106px;
			@media (max-width: $TABLET_BREAKPOINT) {
				width: 100px;
			}
		}

		.carrabbas-logo {
			width: 125px;
			@media (max-width: $TABLET_BREAKPOINT) {
				width: 120px;
			}
		}

		.bonefish-logo {
			width: 65px;
			@media (max-width: $TABLET_BREAKPOINT) {
				width: 60px;
			}
		}

		.flemings-logo {
			width: 125px;
			@media (max-width: $TABLET_BREAKPOINT) {
				width: 120px;
			}
		}
	}

	.container-text {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		padding: 16px 16px 24px 16px;

		@media (max-width: $TABLET_BREAKPOINT) {
			padding: 16px 4px 16px 4px;
		}

		p {
			@include open-sans-font();
			margin: 0;
		}

		.rewardFrontBody {
			@include geomanist-font();
			color: #9b7e20;
			
			display: flex;
			flex-direction: column;
			gap: 8px;
			.valueOff {
				font-size: 18px;
				line-height: 25px;
			}

			.itemOff {
				font-size: 22px;
				line-height: 32.51px;
				@media (max-width: $TABLET_BREAKPOINT) {
					font-size: 20px;
					line-height: 27.09px;
				}
			}
		}

		.description {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			flex-grow: 1;
			padding: 16px;
			line-height: 21.67px;
		}

		.button {
			align-self: center;
			justify-self: flex-end;

			:hover {
				cursor: pointer;
			}

			button {
				color: #38322c;
				@include open-sans-font();
				font-size: 12px;
				line-height: 16.34px;
				font-weight: 700;
				border: none;
				background: transparent;
			}
		}
	}

	label {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.arrowIcon {
		width: 18px;
		height: 18px;
	}

	.background-image-container {
		background-color: #ededed;
		width: 100%;
		height: 120px;
        object-fit: cover;
	}

	.details {
		font-size: 16px;
		line-height: 21.67px;
		@media (max-width: $TABLET_BREAKPOINT) {
			font-size: 12px;
			line-height: 16px;
		}
	}
}
