@import '../../../../../../../shared/utils/variable.scss';

@mixin open-sans-font-small {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 17px;
}

@mixin open-sans-font-large {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 19px;
}

@mixin geomanist-font {
	font-family: 'Geomanist', sans-serif;
	font-size: 24px;
	line-height: 32.51px;
	vertical-align: middle;
}

.header {
	@include geomanist-font();
	color: #333333;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px 32px;
	background-color: #f9f9f5;

	@media (max-width: $TABLET_BREAKPOINT) {
		display: block;
	}

	.icon {
		color: #333333;
		width: 24px;
		height: 24px;
	}

	.title {
		flex: 1;
		text-align: center;
	}

	.dropdown {
		@include open-sans-font-small();
		color: #8a8a8a;
		width: 200px;

		@media screen and (max-width: $TABLET_BREAKPOINT) {
			width: 100%;
			margin: auto;
		}
	}
}

.container {
	display: flex;
	padding: 0px 72px;

	@media (max-width: $TABLET_BREAKPOINT) {
		padding: 0px;
		width: 100dvw;
	}
}

.activityTimelineContainer {
	width: 100%;

	@media screen and (max-width: $TABLET_BREAKPOINT) {
		padding: 0px 24px;
	}
}

.loadMoreContainer {
	padding-bottom: 24px;
}

.loadMoreButtonArea:hover {
	cursor: pointer;
	color: #a74c08;
}

.loadMoreButtonArea {
	@include open-sans-font-large();
	padding: 0px;
	color: #ab6503;
	display: flex;
	align-items: center;
	margin: auto;
	width: max-content;
	gap: 8px;

	p {
		margin: 0px;
	}

	.icon-area {
		width: 14px;
		height: 8px;
	}
}

.noActivityFound {
	margin: auto;
	padding: 24px 0px;
}

.dropdown {
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;
	}
}
