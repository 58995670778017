@import '../../../shared/utils/variable.scss';

@mixin open-sans-font {
	font-family: 'Open Sans', sans-serif;
	line-height: 16.34px;
}

.container {
	position: relative;

	@media screen and (max-width: $TABLET_BREAKPOINT) {
		padding-top: 16px;
		width: unset;
	}
}

.selected {
	@include open-sans-font();
	color: #2c2c2b;
	background-color: #ffffff;
	padding: 20px 16px;
	position: relative;
	cursor: pointer;
	height: 60px;

	@media screen and (max-width: $TABLET_BREAKPOINT) {
		width: unset;
		height: unset;
	}
}

.selectedDisabled {
	@include open-sans-font();
	color: #2c2c2b;
	background-color: #ffffff;
	padding: 20px 16px;
	position: relative;
	cursor: default;
	height: 60px;

	@media screen and (max-width: $TABLET_BREAKPOINT) {
		width: unset;
		height: unset;
	}
}

.chevron {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 12px;
	width: 16px;
	height: 9px;
}

.options {
	@include open-sans-font();
	color: #2c2c2b;
	position: absolute;
	z-index: 10;
	background: #ffffff;
	border: 1px solid #757575;
	border-radius: 8px;
	width: 100%;
	cursor: pointer;
}

.option {
	padding: 0px 16px;
	display: flex;
	height: 32px;

	&:hover {
		background: #a74c08;
		color: #ffffff;
	}
}

.optionText {
	margin: auto 0px;
}

.option:first-of-type {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}

.option:last-of-type {
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
}
