@import '../../../shared/utils/variable.scss';

.container {
	display: flex;
	position: relative;
}

.content {
	flex: 1;
	height: auto;
}

.menu {
	position: relative;

	@media (max-width: $TABLET_BREAKPOINT) {
		display: none;
	}
}

.noScroll {
	position: fixed;
	overflow: hidden;
	height: 100%;
}

.rightContent {
	height: 100%;
}
