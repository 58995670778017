@import "../../../shared/utils/variable.scss";

.container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 3.3dvh 3.5dvw;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&.variant-primary {
		color: #fff;
	}

	&.variant-secondary {
		color: #000;
		background-color: #DED9C7;
		button {
			color: #000000;
		}
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		padding: 3.85dvh 6.15dvw;
	}
}

.logo {
	width: 132px;
	max-width: 9.7dvw;

	@media (max-width: $TABLET_BREAKPOINT) {
		width: auto;
		height: 57.15px;
		max-height: 9.1dvh;
		max-width: none;
	}

	img {
		width: 100%;
		height: 100%;
		display: block;
	}
}

.content {
	display: flex;
	gap: 40px;
}

.video-button {
	button {
		padding: 0;
		background-color: transparent;
		color: white;
		border: none;
		width: 30px;
		height: 30px;
		cursor: pointer;
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		display: none;
	}
}

.drawer-button {
	width: 30px;
	height: 30px;

	@media (max-width: $TABLET_BREAKPOINT) {
		max-width: 4.8dvh;
		max-height: 4.8dvh;
	}

	button {
		padding: 0;
		background-color: transparent;
		color: white;
		border: none;
		width: 100%;
		height: 100%;
		display: block;

		cursor: pointer;
	}
}

.drawer {
	min-height: 100dvh;
	background: #333;

	.top {
		display: flex;
		justify-content: flex-end;
		padding: 32px;

		.close {
			width: 32px;
			color: #fff;
			cursor: pointer;
		}
	}
}