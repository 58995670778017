.container {
	width: 24px;
	height: 24px;
	max-width: 100%;
	max-height: 100%;

	&.custom-size {
		width: 100%;
		height: 100%;
	}

	& svg {
		width: 100%;
		height: 100%;
		display: block;
	}
}
