@import '../../../../../shared/utils/variable.scss';

.container {
    position: relative;
    background-color: #FFFFFF;
    min-height: 80dvh;
    width: 91.8dvw;
    display: flex;
    margin: auto;

    @media (max-width: $TABLET_BREAKPOINT) {
        min-height: 100dvh;
        width: 100dvw;
    }
}

.icon-close {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 4.2%;
    right: 1.9%;
    z-index: 10;
    color: #333333 !important;
    &:hover {
        cursor: pointer;
    }

    @media (max-width: $TABLET_BREAKPOINT) {
        top: 24px;
        right: 24px;
    }
}

.content {
    position: relative;
    width: 100%;
    margin: auto;
}