@import '../../../shared/utils/variable.scss';

.ReservationInput {
	display: flex;
	flex-direction: column;
	text-align: start;

	@media screen and (min-width: $TABLET_BREAKPOINT) {
		min-width: 280px;
	}

	label {
		font-family: 'DinNextLTPro-Regular', sans-serif;
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: 160%;
	}

	input {
		font-family: 'DinNextLTPro-Regular', sans-serif;
		font-size: 1rem;
		font-style: normal;
		line-height: 160%;
		cursor: pointer;
		text-align: start;
	}

	:global(.react-datepicker__day--disabled) {
		color: #ccc;
	}

	:global(.react-datepicker__day--highlighted) {
		background-color: #ded9c7;
		border-radius: 0.3rem;
	}

	:global(.react-datepicker__day--highlighted:hover) {
		background-color: #9b7e20;
	}

	:global(.react-datepicker__day--selected) {
		background-color: #9b7e20;
		border-radius: 0.3rem;
		color: #fff;
	}

	:global(.react-datepicker__day--selected:hover) {
		background-color: #9b7e20;
	}

	:global(.react-datepicker__current-month) {
		font-family: sans-serif !important;
		font-size: 24px;
		padding-top: 8px;
	}
}
