$arrow-width: 11px;
$arrow-margin: 16px;

.container {
    position: relative;
}

.anchor-element {
    width: fit-content;
}

.poligon {
    position: absolute;
    content: '';
    width: 11px;
    height: 6px;
    background-color: #333333;
    z-index: -1;

    &::before {
        position: absolute;
        content: '';
        z-index: 2;
        width: 9px;
        height: 6px;
        background-color: #F7F7F7;
    }

    &.left {
        left: 16px;
    }

    &.left::before {
        right: 16px;
    }

    &.right {
        right: 16px;
    }
    
    &.right::before {
        left: 16px;
    }

    &.top {
        clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
        bottom: -6px;
    }

    &.top::before {
        clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
        bottom: 1px;
        left: 1px;
    }

    &.bottom {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        top: -6px;
    }

    &.bottom::before {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        top: 1px;
        left: 1px;
    }

}

.content {
    background-color: #F7F7F7;
    padding: 16px;
    border: 1px solid #333333;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100dvw;
    position: relative;
    z-index: 1;

    &.small {
        padding: 8px;

        .text {
            font-size: 12px;
        }
    }
}

.text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #333333;
    direction: ltr !important;
}

.tooltip-container {
    display: flex;
    position: absolute;
    z-index: 1;
    margin: 8px 0;
}