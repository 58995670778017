@import '../../../shared/utils/variable.scss';

.container {
	display: flex;
	flex-direction: column;
	gap: 3px;
	font-family: 'Open Sans', sans-serif;
	::placeholder {
		color: #8a8a8a;
	}

	&.has-error {
		input {
			border-bottom-width: 3px;
			border-bottom-color: #b00020;
		}
	}
}

.label {
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	@media (max-width: $TABLET_BREAKPOINT) {
		font-size: 12px;
		line-height: 16.34px;
	}
}

.input {
	width: 100%;
	border: 1px solid #333333;
	padding: 16px 44px 16px 16px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	color: #333333;
	@media (max-width: $TABLET_BREAKPOINT) {
		font-size: 12px;
		line-height: 16.34px;
		padding-right: 40px;
	}
	text-overflow: ellipsis;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input:read-only {
	cursor: default;
}

.input-container {
	position: relative;
}

.icon-container {
	position: absolute;
	width: 22px;
	height: 22px;
	top: 50%;
	transform: translate(-50%, -50%);
	right: 2px;
	color: #333333;
	@media (max-width: $TABLET_BREAKPOINT) {
		width: 14.67px;
		height: 14.67px;
		right: 6px;
	}
}
