@import '../../../shared/utils/variable.scss';

.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #222222;
	z-index: 1;
	display: flex;
	overflow-y: auto;
	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/DR-missing-points-bg.webp');
		background-size: cover;
		opacity: 0.6;
	}
}

.content {
	max-width: 100%;
	padding: 24px;
	& > * {
		margin: 0 auto;
	}
}

.title {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 50px;
	line-height: 44px;
	text-align: center;
	color: #333333;
	margin-bottom: 16px;

	@media (max-width: $TABLET_BREAKPOINT) {
		font-size: 32px;
		line-height: 43.34px;
	}
}

.subtitle {
	font-family: 'Open Sans', sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 38.4px;
	text-align: center;
	margin-top: 0;
	margin-bottom: 40px;

	@media (max-width: $TABLET_BREAKPOINT) {
		font-size: 14px;
		line-height: 17px;
	}
}

.content form {
	width: 440px;
	max-width: 100%;
}

.input-container {
	position: relative;
	padding: 22px 0 48px 0;
	width: auto;
	overflow-y: visible;

	.error {
		position: absolute;
		max-width: 100%;
		margin: 0;
		height: auto;
		left: 0;
		color: #b00020;
		word-wrap: break-word;
		font-family: 'Open Sans', sans-serif;
		font-size: 12px;
		font-weight: 400;
		line-height: 16.34px;
		letter-spacing: 0.02em;
		text-align: left;
	}
}

.content button {
	width: 100%;
	height: 49px;
	border: none;
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.02em;
	text-align: center;
	background-color: #ab6503;
	color: #ffffff;

	&:hover {
		filter: brightness(95%);
		cursor: pointer;
	}
}
