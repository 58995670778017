@import '../../../../../shared/utils/variable.scss';

@mixin center-flex($direction) {
	display: flex;
	flex-direction: $direction;
}

@mixin geomanist-font($size, $height, $spacing) {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: $size;
	line-height: $height;
	letter-spacing: $spacing;
	color: #ffffff;
	text-align: center;
}

@mixin open-sans-font($size, $height, $spacing) {
	font-family: 'Open Sans', sans-serif;
	font-size: $size;
	font-weight: 400;
	line-height: $height;
	letter-spacing: $spacing;
	color: #ffffff;
	text-align: center;
}

@mixin buttons-styling($background, $border-color) {
	height: 49px;
	padding: 16px;
	border: none;
	border-bottom: 3px solid $border-color;
	background: $background;
	&:hover {
		border-bottom: none;
	}
}

@mixin input-font {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0.02em;
	text-align: left;
}

.container {
	@include center-flex(column);
	min-height: 100dvh;
	position: relative;
	overflow-x: hidden;
}

.login-container {
	@include center-flex(column);
	flex: 1;
	align-self: center;
	align-items: center;
	gap: 36px;
	margin-top: 22dvh;
	margin-bottom: 30px;
	overflow: hidden;

	@media (max-width: $TABLET_BREAKPOINT) {
		gap: 12px;
		padding: 0 24px 0 24px;
	}
}

.message {
	@include center-flex(column);
	gap: 16px;

	@media (max-width: $TABLET_BREAKPOINT) {
		gap: 8px;
	}
}

.title-font {
	@include geomanist-font(40px, 54.18px, inherit);

	@media (max-width: $TABLET_BREAKPOINT) {
		@include geomanist-font(32px, 43.34px, inherit);
	}
}

.subtitle-font {
	@include open-sans-font(22px, 29.96px, inherit);
	margin: 0;

	@media (max-width: $TABLET_BREAKPOINT) {
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.02em;
	}
}

.form-container {
	@include center-flex(column);
	gap: 8px;
	width: 340px;

	@media (max-width: $TABLET_BREAKPOINT) {
		gap: 16px;
		width: 87dvw;
		max-width: 340px;
	}
}

.login-form {
	@include center-flex(column);
	gap: 8px;

	@media (max-width: $TABLET_BREAKPOINT) {
		gap: 16px;
	}
}

.user-email {
	@include input-font();
	color: #333333;
	width: 'fill';
	height: '49px';
}

.input-group {
	position: relative;
	align-items: center;
	flex-direction: row;
	.msg {
		@include geomanist-font(12px, 19px, 0.5px);
		text-align: left;
		display: block;
		overflow: hidden;
		margin-top: 8px;

		@media (min-width: $TABLET_BREAKPOINT) {
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translateY(-25%);
			width: 360px;
			margin-left: 10px;
		}
	}
}

.input-label {
	@include input-font();
	color: #8a8a8a;
}

.auth-buttons {
	@include center-flex(row);
	gap: 18px;

	button {
		width: 100%;
		max-width: 161px;
	}
}

.join-now {
	@include geomanist-font(14px, 17px, 0.02em);
	@include buttons-styling(#ab6503, #a74c08);
	cursor: pointer;
}

.sign-in {
	@include geomanist-font(14px, 17px, 0.02em);
	@include buttons-styling(#106c72, #15828a);
	cursor: pointer;
}

.missing-points {
	@include geomanist-font(14px, 17px, 0.02em);
	@include buttons-styling(#26825f, #144331);
	cursor: pointer;
}

.separator {
	display: flex;
	height: 49px;
	align-items: center;
	gap: 8px;

	@media (max-width: $TABLET_BREAKPOINT) {
		height: 17px;
	}

	hr {
		width: 100%;
		height: 1px;
		border: 0;
		border-top: 1px solid #ededed;
	}
}

.separator-text-font {
	@include open-sans-font(12px, 14.4px, 0.01em);
}

.logos-container {
	@include center-flex(row);
	align-items: center;
	justify-content: center;
	gap: 56px;
	flex-shrink: 0;
	position: relative;
	margin-bottom: 24px;
	@media (max-width: $TABLET_BREAKPOINT) {
		gap: 24px;
	}
	img[data-testid='outback-logo'] {
		width: 86px;
		height: 33px;
		@media (max-width: $TABLET_BREAKPOINT) {
			width: 68px;
			height: 26.09px;
		}
	}
	img[data-testid='carrabbas-logo'] {
		width: 118px;
		height: 23px;
		@media (max-width: $TABLET_BREAKPOINT) {
			width: 68px;
			height: 13.25px;
		}
	}
	img[data-testid='bonefishgrill-logo'] {
		width: 57px;
		height: 40px;
		@media (max-width: $TABLET_BREAKPOINT) {
			width: 48px;
			height: 33px;
		}
	}
	img[data-testid='flemings-logo'] {
		width: 112px;
		height: 28px;
		@media (max-width: $TABLET_BREAKPOINT) {
			width: 68px;
			height: 17px;
		}
	}
}
