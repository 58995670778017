.drawer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100svh;
	max-height: 100svh;
	background: #333;
	color: #fff;

	.top-drawer {
		text-align: left;
		padding: 32px;
		width: 100%;
		flex-grow: 1;
		min-height: max-content;
		align-self: flex-start;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 16px;

		.close {
			border: none;
			background-color: transparent;
			padding: 0;
			width: 32px;
			height: 32px;
			cursor: pointer;
			color: #8a8a8a;
			:hover {
				color: #fff;
			}
		}
	}
	.content {
		.links {
			padding-left: 0;
			display: flex;
			flex-direction: column;
			gap: 16px;
			list-style-type: none;
			font-weight: 700;
			font-size: 20px;
			line-height: 22px;
			cursor: pointer;
			margin: 0;
			li {
				&:hover {
					text-decoration: underline;
					text-decoration-color: inherit;
				}
			}
		}
	}
	.border {
		margin: 24px 0;
		border-top: 1px solid #fff;
		width: 16px;
	}
	.sublinks {
		.links {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding-left: 0;
			list-style-type: none;
			color: #8a8a8a;
			font-size: 12px;
			font-weight: 400;
			text-decoration: underline;
			margin: 0;
			li {
				&:hover {
					cursor: pointer;
					opacity: 0.8;
				}
			}
		}
	}
	.bottom-drawer {
		font-size: 10px;
		color: #8a8a8a;
	}
	.brand-section {
		display: grid;
		grid-template-columns: 2fr 2fr;
		.brand-button {
			position: relative;
			display: grid;
			place-items: center;
			border: 1px solid #8a8a8a;
			height: 135px;

			&:hover {
				div {
					color: #fff;
				}
			}

			&.outback-button {
				&:hover {
					background: #661615;
					color: #fff;
				}
				img {
					width: 66px;
				}
			}
			&.carrabbas-button {
				&:hover {
					background: #3c7d5b;
					color: #fff;
				}
				img {
					width: 69px;
				}
			}
			&.bonefish-button {
				&:hover {
					background: #957721;
					color: #fff;
				}
				img {
					width: 43px;
				}
			}
			&.flemings-button {
				&:hover {
					background: #a50034;
					color: #fff;
				}
				img {
					width: 81px;
				}
			}

			.brand-button-arrow {
				width: 16px;
				color: #8a8a8a;
				position: absolute;
				top: 8px;
				right: 8px;
				height: 16px;
			}
		}
	}
}
