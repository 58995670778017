@import '../../../../../../../shared/utils/variable.scss';

@mixin open-sans-font {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	text-align: center;
	vertical-align: middle;
	color: #ffffff;
}
@mixin geomanist-font {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 24px;
	line-height: 32.51px;
	text-align: center;
	vertical-align: middle;
	color: #ffffff;
}

.body {
	display: flex;
	flex-direction: column;
	gap: 56px;
	padding: 56px 100px 56px 100px;
	@media (max-width: $TABLET_BREAKPOINT) {
		margin-top: 60px;
		gap: 40px;
		padding: 40px 24px 40px 24px;
	}
}

.section {
	display: flex;
	flex-direction: column;
	gap: 56px;
	@media (max-width: $TABLET_BREAKPOINT) {
		gap: 32px;
	}
}

.header {
	@include geomanist-font();
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;

	.icon {
		width: 24px;
		height: 24px;
	}

	.first-subtitle {
		@include open-sans-font();
	}
}

.flex-container {
	display: flex;
	flex-direction: column;
	gap: 36px;
	@media (max-width: $TABLET_BREAKPOINT) {
		padding: 0px;
	}
	.cards-container {
		display: grid;
		align-items: stretch;
		justify-content: center;
		grid-template-columns: auto auto auto;
		grid-auto-rows: 1fr;
		grid-column-gap: 32px;
		grid-row-gap: 36px;
		overflow-y: hidden;

		@media (max-width: $TABLET_BREAKPOINT) {
			grid-template-columns: repeat(auto-fill, 100%);
			grid-column-gap: 16px;
			grid-row-gap: 24px;
		}

		.card {
			width: 100%;
			height: 100%;
			background-color: #ffffff;
			overflow-y: hidden;
		}
	}
	.loadMoreBtn {
		display: flex;
		align-items: center;
		gap: 8px;
		align-self: center;
		max-width: fit-content;
		@include open-sans-font();
		font-weight: 700;
		line-height: 19.07px;
		color: #f9f9f5;
		border: none;
		background: transparent;
		div {
			height: 8px;
		}
		&:hover,
		label:hover {
			cursor: pointer;
		}
		.arrowUp {
			transform: rotate(180deg);
		}
	}
	.second-subtitle {
		@include open-sans-font();
		margin: 0;
	}
	.zeroRewards {
		@include geomanist-font();
	}
}

.bottom-links {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: start;

	.link-title {
		@include geomanist-font();
	}
	.brand-link {
		@include open-sans-font();
		font-weight: 700;
		line-height: 19px;
		text-decoration: underline;
	}
	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;
		background: transparent;
		border: none;
	}

	.deeplink-brand-section {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: start;
		justify-items: center;
		justify-content: center;
		gap: 24px;
		@media (max-width: $TABLET_BREAKPOINT) {
			width: 100%;
			justify-content: space-between;
		}

		.deeplink-brand {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 16px;
			background: transparent;
			border: none;
			padding: 0px;
			:hover {
				cursor: pointer;
			}

			img {
				width: 70px;
				height: 70px;
				border-radius: 16px;
				@media (max-width: $TABLET_BREAKPOINT) {
					width: 55px;
					height: 55px;
				}
			}
			@media (max-width: $TABLET_BREAKPOINT) {
				span {
					max-width: 80px;
				}
				:not(:nth-child(2)) {
					span {
						max-width: min-content;
					}
				}
			}
		}
	}

	.flemings-link-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;
		background: transparent;
		border: none;
		:hover {
			cursor: pointer;
		}
		img {
			height: 70px;
			border-radius: 1px;
		}
		p {
			margin: 0;
		}
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		flex-direction: column;
		width: 100%;
		gap: 40px;
		align-items: center;
	}

	@media (max-width: 1000px) and (min-width: ($TABLET_BREAKPOINT + 1px)) {
		gap: 40px;
		flex-flow: column;
		align-items: center;
	}
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.w-100 {
	width: 100%;
}
