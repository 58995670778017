@import '../../../shared/utils/variable.scss';

@mixin center-flex($direction) {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: $direction;
}

@mixin text-format($size, $lineHeight, $weight) {
	color: var(--tertiary-dark, #333333);
	font-family: 'Open Sans', sans-serif;
	font-size: $size;
	line-height: $lineHeight;
	font-weight: $weight;
}

@mixin text-format-geomanist($size, $lineHeigth) {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: $size;
	line-height: $lineHeigth;
}

.container {
	max-width: 960px;
	margin: 45px auto 55px;

	@media (max-width: $TABLET_BREAKPOINT) {
		.footer {
			display: block;
		}
	}

	@media (min-width: ($TABLET_BREAKPOINT + 1px)) {
		margin: 180px auto 55px;
	}
}

.header {
	text-align: center;

	h1 {
		@include text-format-geomanist(32px, 43px);
		line-height: 43.34px;
	}

	p {
		@include text-format(12px, 19px, 400);
		padding: 0 24px;
	}

	@media (max-width: $TABLET_BREAKPOINT) {
		margin: 120px 0 0;

		h1 {
			text-transform: uppercase;
			margin: 0 0 16px;
		}

		P {
			padding: 0;
		}
	}

	@media (min-width: ($TABLET_BREAKPOINT + 1px)) {
		position: fixed;
		z-index: 500;
	}
}

.content {
	margin: 32px 0 0;
	background: #fff;
}

.mobileTitle {
	display: none;

	@media (max-width: $TABLET_BREAKPOINT) {
		display: block;
	}
}

.desktopTitle {
	@media (max-width: $TABLET_BREAKPOINT) {
		display: none;
	}
}

.footer {
	display: none;
	font-size: 14px;
	color: #333333;
	text-align: center;
	padding: 24px 0 16px 0;

	@media (max-width: $TABLET_BREAKPOINT) {
		display: block;
	}
}

@media (min-width: ($TABLET_BREAKPOINT + 1px)) {
	.fixed {
		position: absolute;
		top: 0;
		width: 100%;
		text-align: center;
		padding: 1rem;
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.m-40 {
		margin: 40px;
	}

	.top-0 {
		top: 0;
	}
}

.w-50 {
	width: 50%;
}

.w-75 {
	width: 75%;
}

.m-auto {
	margin: 0 auto;
}

@media (max-width: $TABLET_BREAKPOINT) {
	.m-120-auto {
		margin: 120px auto 0;
	}

	.w-100-mobile {
		width: 100%;
	}
}
