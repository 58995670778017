.container {
	&.floating-label {
		label {
			top: 0;
			left: 0;
			color: #fff;
		}
	}

	&.has-error {
		input {
			border-bottom-color: red;
		}
	}
}

.top {
	position: relative;
	padding: 20px 0 0;

	label {
		position: absolute;
		top: 36px;
		left: 16px;
		transition: all ease 0.2s;
		pointer-events: none;
	}

	input {
		padding: 0 60px 0 16px;
		background: #fff;
		width: 100%;
		outline: 0;
		height: 50px;
		border: none;
		border-bottom: 2px solid transparent;
	}

	.icon {
		position: absolute;
		top: 32px;
		right: 8px;
		color: rgb(117, 117, 117);
	}
}

.bottom {
	.error {
		margin: 8px 0 0;
		color: #fff;
	}
}
