@import '../../../../../shared/utils/variable.scss';

.container {
	@media (min-width: calc($TABLET_BREAKPOINT + 1px)) {
		display: none;
	}

	display: flex;
	align-items: center;
	justify-content: space-between;

	background: var(--secondary-color, #38322C);
	padding: 16px;
	color: #FFFFFF;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 10;

	.logo {
		width: 50px;
		height: 30.6px;
		background: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/dine-rewards-logo-white.svg')
			center left no-repeat;
		background-size: contain;
	}

	.icon-back {
		cursor: pointer;
	}

	.user {
		width: 20px;
		height: 20px;

		a {
			color: #FFFFFF !important;
		}
	}
}
