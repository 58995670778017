@import '../../../shared/utils/variable.scss';

@mixin open-sans-font {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: start;
	color: #2c2c2b;
}

@mixin geomanist-font {
	font-family: 'Geomanist-bold', sans-serif;
	font-size: 32px;
	line-height: 36px;
	text-align: start;
	color: #121211;
}

.modalOverlay {
	width: 100%;
	height: calc(100% + 65px);
	z-index: 99;
	top: -65px;
	left: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
}

.modalContainer {
	width: 85%;
	top: calc(50% + 65px);
	transform: translateY(-50%);
	position: relative;
	background-color: #ffffff;
	margin: auto;
	border-radius: 4px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	max-width: 550px;

	@media screen and (min-width: $DESKTOP_LARGE_BREAKPOINT) {
		width: 26.2%;
	}
}

.buttonsContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.title {
	@include geomanist-font();
	padding-top: 16px;
}

.text {
	@include open-sans-font();
	margin: 0;
}

.modalCloseIcon {
	color: #a74c08;
	position: absolute;
	right: 16px;
	top: 16px;
	border: 0;
	padding: 0;
	background-color: transparent;
	cursor: pointer;

	:hover {
		opacity: 0.8;
	}
}

.button {
	@include open-sans-font();
	font-weight: 700;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	background-color: #a74c08;
	border: none;
	padding: 16px 23px;
	text-decoration: none;
	cursor: pointer;

	:hover {
		opacity: 0.8;
	}
}

.status {
	@include open-sans-font();
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	margin-top: 8px;
}