.video {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.main {
	position: relative;
	z-index: 3;
}
