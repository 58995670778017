@import '../../../../../../../shared/utils/variable.scss';

@mixin open-sans-font-small {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
}

@mixin open-sans-font-regular {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
}

@mixin open-sans-font-bold {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 19px;
}

.activityTimelineCard {
	width: 100%;
	padding-top: 24px;

	@media screen and (max-width: $TABLET_BREAKPOINT) {
		padding-top: 16px;
	}
}

.activityTimelineCard:not(:last-child) {
	border-bottom: 1px solid #8a8a8a;
}

.activityTimelineInfo {
	padding-bottom: 12px;
	display: flex;
	gap: 12px;
}

.activityTimelineTitle {
	flex: 1;
	color: #2C2C2B;
	margin: auto;
}

.activityTimelineExpiration {
	@include open-sans-font-small();
	color: #8A8A8A;
	margin: auto;
	gap: 8px;
	display: flex;
}

.activityTimelineExpirationMobile {
	@include open-sans-font-small();
	color: #8A8A8A;
	gap: 8px;
	display: flex;
	padding-bottom: 16px;
	width: max-content;
}

.activityTimelineExpirationText {
	margin: auto;
	color: #000000;
}

.activityTimelineViewDetails {
	margin-bottom: 24px;

	@media screen and (max-width: $TABLET_BREAKPOINT) {
		margin-bottom: 16px;
	}
}

.viewDetailsButtonArea:hover {
	cursor: pointer;
	color: #a74c08;
}

.viewDetailsButtonArea {
	color: #8a8a8a;
	width: max-content;
}

.learnMoreButtonArea:hover {
	cursor: pointer;
	color: #1c1a1a;
}

.learnMoreButtonArea {
	color: #707070;
	width: max-content;
	margin-top: 8px;
}

.rewardDescription {
	@include open-sans-font-regular();
	margin-top: 40px;
}

.viewDetailsButton {
	font-weight: 700;
	font-size: 12px;
}

.learnMoreButton {
	font-weight: 700;
	font-size: 12px;
}

.activityTitle {
	@include open-sans-font-bold();
}

.activityDate {
	@include open-sans-font-regular();
}

.highlightPrice {
	color: #ab6503;
	font-size: 14px;
	font-weight: 700;
}

.detailsSection {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-out;
}

.isExpanded {
	max-height: 500px;
	transition: max-height 0.5s ease-in;
}
