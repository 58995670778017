@import '../../../shared/utils/variable.scss';

.backdrop {
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	z-index: 120;
	transition: all ease 0.3s;

	&.is-open {
		animation: backdrop-enter 0.3s linear 0s 1;
		display: block;
		opacity: 1;
	}

	&.is-closing {
		display: block;
		opacity: 0;
	}
}

@keyframes backdrop-enter {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.container {
	--width: 22%;
	--max-width: 80%;

	background: #333;
	position: fixed;
	top: 0;
	right: calc(-1 * var(--width));
	width: var(--width);
	min-width: 304px;
	max-width: var(--max-width);
	height: 100%;
	z-index: 1000;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	transition: all ease 0.3s;

	&.is-open {
		animation: container-right-enter 0.3s ease-in 0s 1;
		right: 0;
	}

	&.is-closing {
		right: calc(-1 * var(--width));
	}

	&.direction-left {
		right: unset;
		left: calc(-1 * var(--width));

		&.is-open {
			animation: container-left-enter 0.3s ease-in 0s 1;
			left: 0;
		}

		&.is-closing {
			left: calc(-1 * var(--width));
		}
	}

	&.mobile-full-screen {
		@media (max-width: $TABLET_BREAKPOINT) {
			--width: 100%;
			--max-width: 100%;
		}
	}
}

@keyframes container-right-enter {
	from {
		right: calc(-1 * var(--width));
	}
	to {
		right: 0;
	}
}

@keyframes container-left-enter {
	from {
		left: calc(-1 * var(--width));
	}
	to {
		left: 0;
	}
}

.content {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	& > div {
		width: 100%;
	}
}
